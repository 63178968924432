import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InvoicesController from '../../../services/invoices'
import CheckButton from '../../../components/checkbutton'
import AccountStore from '../../../store/user'
export default class UserSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      id:"",
      tipo:"fisica",
      nombre:"",
      apellido1:"",
      apellido2:"",
      rfc:"",
      curp:"",
      tipo_cliente:"",
      telefono:"",
      celular:"",
      email:"",
      domicilio:"",
      colonia:"",
      cp:"",
      estado:"",
      municipio:"",
      moral_rfc:"",
      moral_razon_social:"",
      moral_telefono:"",
      moral_celular:"",
      moral_email:"",
      moral_domicilio:"",
      moral_colonia:"",
      moral_cp:"",
      moral_estado:"",
      moral_municipio:"",
      
      
      name: "",
      familyname: "",
      email: "",
      mobile: "",
      company: "Invarat",
      role: 0,
      active: true,
      loading: false,
      saving: false,
      companies: ["Invarat"], // Listado de compañias a las que pertenece
      redirect: false,
      adjusters: [],
      adjustersList: []
    }
  }
  componentDidMount() {
    const { params } = this.props.match
    if (params) {
      if (params.id) this.init(params.id)
    }
    AccountStore.on('update_company', () => this.setState({ redirect: true }))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({ redirect: true }))
  }
  init = async (id) => {
    this.setState({ loading: true })
    try {
      const user = await InvoicesController.getById(id)
      //console.log("user=",user)
      
      this.setState({
        id: id,
        tipo:user.tipo
        ,nombre:user.nombre
        ,apellido1:user.apellido1
        ,apellido2:user.apellido2
        ,rfc:user.rfc
        ,curp:user.curp
        ,tipo_cliente:"nd"
        ,telefono:user.telefono
        ,celular:user.celular
        ,email:user.email
        ,domicilio:user.domicilio
        ,colonia:user.colonia
        ,cp:user.cp
        ,estado:user.estado
        ,municipio:user.municipio
        ,moral_rfc:user.moral_rfc
        ,moral_razon_social:user.moral_razon_social
        ,moral_telefono:user.moral_telefono
        ,moral_celular:user.moral_celular
        ,moral_email:user.moral_email
        ,moral_domicilio:user.moral_domicilio
        ,moral_colonia:user.moral_colonia
        ,moral_cp:user.moral_cp
        ,moral_estado:user.moral_estado
        ,moral_municipio:user.moral_municipio
        ,loading: false
      })
    } catch (e) {
      console.log(e)
      this.setState({ loading: false })
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
    // console.log(e.target.value)
    console.log(e.target.id)
    if (e.target.id === 'role') {
      if (parseInt(e.target.value) === 4) {
        this.loadAdjusters(this.state.id)
      } else {
        this.clearAdjusters()
      }
    }
  }
  toggleCompany = (value) => {
    let array = [...this.state.companies]
    if (array.indexOf(value) !== -1) {
      if (array.length === 1) return
      array.splice(array.indexOf(value), 1)
    } else {
      array.push(value)
    }
    if (!array.includes('Siniestros') && [3, 4].includes(parseInt(this.state.role))) {
      // Si se quita siniestros y se tenia seteado el rol ajustador o coordinador setear rol a administrador
      this.setState({ role: 0 })
      // Limpiar listado de ajustadores
      this.clearAdjusters()
    }
    this.setState({ companies: array })
  }
  toggleAdjuster = (value) => {
    const array = [...this.state.adjusters]
    if (array.indexOf(value) !== -1) {
      // if (array.length === 1) return
      array.splice(array.indexOf(value), 1)
    } else {
      array.push(value)
    }
    this.setState({ adjusters: array })
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  loadAdjusters = async (id) => {
    try {
      /**
       * Enviar id del usuario actual
       * si es create traera solo usuarios no asignados a otro cordinador
       * si es update traera usuarios no asignados a otro cordinador + los asignados a el
       */
      const { result } = await InvoicesController.getAdjusters(id)
      this.setState({ adjustersList: result })
    } catch (e) {
      console.log(e)
    }
  }
  clearAdjusters = () => {
    this.setState({ adjustersList: [], adjusters: [] })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.id) {
      this.handleUpdate(this.state.id)
    } else {
      this.handleCreate()
    }
  }
  handleCreate = async () => {
    try {

      /* 
      const jsonString = '{ "result": { "field": "data" } }';
      const jsonObj = {"result":{"tipo":"fisica","nombre":"Alejandra Monique","apellido1":"Suazo","apellido2":"Ibarra","rfc":"CEEJ7811052F5","curp":"CESK090812MJCCZRA0","tipo_cliente":"nd","telefono":"3314407450","celular":"3314407450","email":null,"domicilio":"Bosque real","colonia":"Bosque real","cp":"45645","estado":"Jalisco","municipio":"Zapopan","updated_at":"2023-03-23T22:23:06.000000Z","created_at":"2023-03-23T22:23:06.000000Z","id":19}}
      console.log(jsonObj.result.tipo); 
      return;
      */
     

      this.setState({ saving: true })
      const { tipo
        ,nombre
        ,apellido1
        ,apellido2
        ,rfc
        ,curp
        ,telefono
        ,celular
        ,email
        ,domicilio
        ,colonia
        ,cp
        ,estado
        ,municipio
        ,moral_rfc
        ,moral_razon_social
        ,moral_telefono
        ,moral_celular
        ,moral_email
        ,moral_domicilio
        ,moral_colonia
        ,moral_cp
        ,moral_estado
        ,moral_municipio
       } = this.state
      
      let data; 
      if (tipo==='fisica'){
        data = {
          tipo:tipo
          ,nombre:nombre
          ,apellido1:apellido1
          ,apellido2:apellido2
          ,rfc:rfc
          ,curp:curp
          ,tipo_cliente:"nd"
          ,telefono:telefono
          ,celular:celular
          ,email:email
          ,domicilio:domicilio
          ,colonia:colonia
          ,cp:cp
          ,estado:estado
          ,municipio:municipio
          ,moral_rfc:""
          ,moral_razon_social:""
          ,moral_telefono:""
          ,moral_celular:""
          ,moral_email:""
          ,moral_domicilio:""
          ,moral_colonia:""
          ,moral_cp:""
          ,moral_estado:""
          ,moral_municipio:""
        };
      }

      if (tipo==='moral'){
        data = {
          tipo:tipo
          ,nombre:nombre
          ,apellido1:apellido1
          ,apellido2:apellido2
          ,rfc:rfc
          ,curp:curp
          ,tipo_cliente:"nd"
          ,telefono:telefono
          ,celular:celular
          ,email:email
          ,domicilio:domicilio
          ,colonia:colonia
          ,cp:cp
          ,estado:estado
          ,municipio:municipio
          ,moral_rfc:moral_rfc
          ,moral_razon_social:moral_razon_social
          ,moral_telefono:moral_telefono
          ,moral_celular:moral_celular
          ,moral_email:moral_email
          ,moral_domicilio:moral_domicilio
          ,moral_colonia:moral_colonia
          ,moral_cp:moral_cp
          ,moral_estado:moral_estado
          ,moral_municipio:moral_municipio
        };
      }
      const oResult = await InvoicesController.create(data)
      alert("¡Listo! El usuario se creo correctamente." )
      this.setState({ id: oResult.result.id, saving: false })
      
      
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  handleUpdate = async (id) => {
    try {
      this.setState({ saving: true })
      const { tipo
        ,nombre
        ,apellido1
        ,apellido2
        ,rfc
        ,curp
        ,telefono
        ,celular
        ,email
        ,domicilio
        ,colonia
        ,cp
        ,estado
        ,municipio
        ,moral_rfc
        ,moral_razon_social
        ,moral_telefono
        ,moral_celular
        ,moral_email
        ,moral_domicilio
        ,moral_colonia
        ,moral_cp
        ,moral_estado
        ,moral_municipio
       } = this.state
      
      let data; 
      if (tipo==='fisica'){
        data = {
          id:id
          ,tipo:tipo
          ,nombre:nombre
          ,apellido1:apellido1
          ,apellido2:apellido2
          ,rfc:rfc
          ,curp:curp
          ,tipo_cliente:"nd"
          ,telefono:telefono
          ,celular:celular
          ,email:email
          ,domicilio:domicilio
          ,colonia:colonia
          ,cp:cp
          ,estado:estado
          ,municipio:municipio
          ,moral_rfc:""
          ,moral_razon_social:""
          ,moral_telefono:""
          ,moral_celular:""
          ,moral_email:""
          ,moral_domicilio:""
          ,moral_colonia:""
          ,moral_cp:""
          ,moral_estado:""
          ,moral_municipio:""
        };
      }

      if (tipo==='moral'){
        data = {
          id:id
          ,tipo:tipo
          ,nombre:nombre
          ,apellido1:apellido1
          ,apellido2:apellido2
          ,rfc:rfc
          ,curp:curp
          ,tipo_cliente:"nd"
          ,telefono:telefono
          ,celular:celular
          ,email:email
          ,domicilio:domicilio
          ,colonia:colonia
          ,cp:cp
          ,estado:estado
          ,municipio:municipio
          ,moral_rfc:moral_rfc
          ,moral_razon_social:moral_razon_social
          ,moral_telefono:moral_telefono
          ,moral_celular:moral_celular
          ,moral_email:moral_email
          ,moral_domicilio:moral_domicilio
          ,moral_colonia:moral_colonia
          ,moral_cp:moral_cp
          ,moral_estado:moral_estado
          ,moral_municipio:moral_municipio
        };
      }
      
      const oResult = await InvoicesController.update(data)
      console.log(oResult)
      alert("¡Listo! El usuario se actualizó correctamente." )
      this.setState({ id: oResult.result.id, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  handleBlock = async () => {
    try {
      this.setState({ saving: true })
      const result = await InvoicesController.block(this.state.id)
      console.log(result)
      alert("¡Listo! El usuario se bloqueo correctamente.")
      this.setState({ active: false, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  handleUnblock = async () => {
    try {
      this.setState({ saving: true })
      const result = await InvoicesController.unblock(this.state.id)
      console.log(result)
      alert("¡Listo! El usuario se actualizó correctamente.")
      this.setState({ active: true, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  render() {
    const { 
      id,
      tipo,
      nombre,
      apellido1,
      apellido2,
      rfc,
      curp,
      tipo_cliente,
      telefono,
      celular,
      email,
      domicilio,
      colonia,
      cp,
      estado,
      municipio,
      moral_rfc,
      moral_razon_social,
      moral_telefono,
      moral_celular,
      moral_email,
      moral_domicilio,
      moral_colonia,
      moral_cp,
      moral_estado,
      moral_municipio,
      name, familyname, mobile, role, saving, companies, adjusters, adjustersList, redirect } = this.state
    // const adjustersSelectHeight = (adjustersList.length * 19 + 20) < 160 ? (adjustersList.length * 19 + 22) : 160
    if (redirect) {
      return <Redirect to="/" />
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button className="btn toggle" onClick={this.toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <Link to="/invoices" className="btn toggle">
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
              <h2 className="uppercase title">{"Facturación"}</h2>
            </div>
            <form>
              <div className="form-group">
                <div className="item">
                  <h3>Datos del cliente</h3>

                  <div className="form-group">
                    <div className="item">
                        <label>Tipo:</label>
                        <div className="select-wrapper">
                          <select id="tipo" value={tipo} onChange={this.handleChange}>
                            <option value='fisica'>Persona fisica</option>
                            <option value='moral'>Persona moral</option>
                            
                          </select>
                          <FontAwesomeIcon icon="chevron-down" />
                        </div>
                      </div>
                  </div>

                  {tipo === 'fisica' ? 
                  <>
                    <div className="form-group">
                      <div className="item">
                        <label>Nombre:</label>
                        <input id="nombre" onChange={this.handleChange} type="text" value={nombre} />
                      </div>
                      <div className="item">
                        <label>Primer apellido:</label>
                        <input id="apellido1" onChange={this.handleChange} type="text" value={apellido1} />
                      </div>
                      <div className="item">
                        <label>Segundo apellido:</label>
                        <input id="apellido2" onChange={this.handleChange} type="text" value={apellido2} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>RFC:</label>
                        <input id="rfc" onChange={this.handleChange} type="text" value={rfc} />
                      </div>
                      <div className="item">
                        <label>Curp:</label>
                        <input id="curp" onChange={this.handleChange} type="text" value={curp} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Teléfono:</label>
                        <input id="telefono" onChange={this.handleChange} type="text" value={telefono} />
                      </div>
                      <div className="item">
                        <label>Celular:</label>
                        <input id="celular" onChange={this.handleChange} type="text" value={celular} />
                      </div>
                      <div className="item">
                        <label>Email:</label>
                        <input id="email" onChange={this.handleChange} type="text" value={email} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Domicilio:</label>
                        <input id="domicilio" onChange={this.handleChange} type="text" value={domicilio} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Colonia:</label>
                        <input id="colonia" onChange={this.handleChange} type="text" value={colonia} />
                      </div>
                      <div className="item">
                        <label>Municipio:</label>
                        <input id="municipio" onChange={this.handleChange} type="text" value={municipio} />
                      </div>
                      <div className="item">
                        <label>Código Postal:</label>
                        <input id="cp" onChange={this.handleChange} type="text" value={cp} />
                      </div>
                      <div className="item">
                        <label>Estado:</label>
                        <input id="estado" onChange={this.handleChange} type="text" value={estado} />
                      </div>
                      
                    </div>
                  </>
                  :null}



                  {tipo === 'moral' ? 
                  <>
                    
                    <div className="form-group">
                      <div className="item">
                        <label>RFC:</label>
                        <input id="moral_rfc" onChange={this.handleChange} type="text" value={moral_rfc} />
                      </div>
                      <div className="item">
                        <label>Razón Social:</label>
                        <input id="moral_razon_social" onChange={this.handleChange} type="text" value={moral_razon_social} />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="item">
                        <label>Teléfono:</label>
                        <input id="moral_telefono" onChange={this.handleChange} type="text" value={moral_telefono} />
                      </div>
                      <div className="item">
                        <label>Celular:</label>
                        <input id="moral_celular" onChange={this.handleChange} type="text" value={moral_celular} />
                      </div>
                      <div className="item">
                        <label>Email:</label>
                        <input id="moral_email" onChange={this.handleChange} type="text" value={moral_email} />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="item">
                        <label>Domicilio:</label>
                        <input id="moral_domicilio" onChange={this.handleChange} type="text" value={moral_domicilio} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Colonia:</label>
                        <input id="moral_colonia" onChange={this.handleChange} type="text" value={moral_colonia} />
                      </div>
                      <div className="item">
                        <label>Municipio:</label>
                        <input id="moral_municipio" onChange={this.handleChange} type="text" value={moral_municipio} />
                      </div>
                      <div className="item">
                        <label>Código Postal:</label>
                        <input id="moral_cp" onChange={this.handleChange} type="text" value={moral_cp} />
                      </div>
                      <div className="item">
                        <label>Estado:</label>
                        <input id="moral_estado" onChange={this.handleChange} type="estado" value={moral_estado} />
                      </div>
                      
                    </div>


                    <h3>Datos del representante</h3>
                    <div className="form-group">
                      <div className="item">
                        <label>Nombre:</label>
                        <input id="nombre" onChange={this.handleChange} type="text" value={nombre} />
                      </div>
                      <div className="item">
                        <label>Primer apellido:</label>
                        <input id="apellido1" onChange={this.handleChange} type="text" value={apellido1} />
                      </div>
                      <div className="item">
                        <label>Segundo apellido:</label>
                        <input id="apellido2" onChange={this.handleChange} type="text" value={apellido2} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>RFC:</label>
                        <input id="rfc" onChange={this.handleChange} type="text" value={rfc} />
                      </div>
                      <div className="item">
                        <label>Curp:</label>
                        <input id="curp" onChange={this.handleChange} type="text" value={curp} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Teléfono:</label>
                        <input id="telefono" onChange={this.handleChange} type="text" value={telefono} />
                      </div>
                      <div className="item">
                        <label>Celular:</label>
                        <input id="celular" onChange={this.handleChange} type="text" value={celular} />
                      </div>
                      <div className="item">
                        <label>Email:</label>
                        <input id="email" onChange={this.handleChange} type="text" value={email} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Domicilio:</label>
                        <input id="domicilio" onChange={this.handleChange} type="text" value={domicilio} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="item">
                        <label>Colonia:</label>
                        <input id="colonia" onChange={this.handleChange} type="text" value={colonia} />
                      </div>
                      <div className="item">
                        <label>Municipio:</label>
                        <input id="municipio" onChange={this.handleChange} type="text" value={municipio} />
                      </div>
                      <div className="item">
                        <label>Código Postal:</label>
                        <input id="cp" onChange={this.handleChange} type="text" value={cp} />
                      </div>
                      <div className="item">
                        <label>Estado:</label>
                        <input id="estado" onChange={this.handleChange} type="text" value={estado} />
                      </div>
                      
                    </div>
                  </>
                  :null}
                  
                  
                  
                </div>
              </div>
            </form>
            <div className="flexbox">
              <button className="btn success" onClick={this.handleSubmit} disabled={saving}>{saving ? "Guardando" : "Guardar"}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
