import api from './api'
import UserStore from '../store/user'
class Controller {
    async getAll(page, data) {
        let url = `/admin/customerbilling/get/all?page=${page}`
        if (data) url += `&query=${data}`
        try {
          const request = await api.request('get', url)
          return request.data
        } catch (error) {
          throw new Error(error)
        }
    }
    async create(data) {
        try {
          const request = await api.request('post', '/admin/customerbilling/create', data)
          return request.data
        } catch (error) {
          throw new Error(error)
        }
    }  
    async update(data) {
        try {
          const request = await api.request('post', '/admin/customerbilling/update', data)
          return request.data
        } catch (error) {
          throw new Error(error)
        }
      }
    async getById(id) {
        try {
          const request = await api.request('get', '/admin/customerbilling/get/id/' + id)
          return request.data
        } catch (error) {
          throw new Error(error)
        }
      }
}    
const InvoicesController = (new Controller())
export default InvoicesController